import "core-js/modules/es.array.push.js";
import { FloatingBubble, showToast } from 'vant';
export default {
  components: {
    FloatingBubble
  },
  mounted: function () {
    console.log('mounted');
  },
  methods: {
    onClick() {
      this.$router.push({
        path: '/index'
      });
    }
  },
  mounted: function () {
    this.$router.push({
      path: '/old/index'
    });
  }
};